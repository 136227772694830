import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import NotFound from "./views/404.vue"
import axios from "axios";
import Lucien from "lucien-form-builder";

var consts = require("./config");

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: '/404', component: NotFound },  
    { path: '*', redirect: '/404' },      
    { path: "/", component: Home },
    {
      path: "/quiz",
      name: "quiz",
      component: () => import("./views/Quiz.vue"),
    },
    {
      path: "/share/:_profil",
      name: "shareHome",
      component: Home,
    },
    {
      path: "/profils",
      name: "profils",
      component: () => import("./views/Profils.vue"),
    },  
    {
      path: "/reset",
      name: "reset",
      component: () => import("./views/Reset.vue"),
    },   
    {
      path: "/img/profils/:_myascendant",
      name: "profils",
      component: () => import("./views/Profils.vue"),
    },       
    {
      path: "/resultats",
      name: "resultats",
      component: () => import("./views/Resultats.vue"),
    },
    {
      path: "/profil/:_profil",
      name: "profil",
      component: () => import("./views/Profils.vue"),
    },        
    {
      path: "/demo/:_profil",
      name: "demo",
      component: () => import("./views/Resultats.vue"),
    },    
    {
      path: "/mentions",
      name: "mentions",
      component: () => import("./views/Mentions.vue"),
    },       
    {
      path: "/campagne",
      name: "campagne",
      component: () => import("./views/Campagne.vue"),
    },
    {
      path: "/calcul",
      name: "calcul",
      component: () => import("./views/Calcul.vue"),
    },    
    {
      path: "/foodistar",
      name: "foodistar",
      component: () => import("./views/Videos.vue"),
    }
  ],
});

export default router;

/*GET FORM DATA INFOS */
function getData() {
  /*
  console.log('a');
  try {
    return axios.get(
      "https://api.lucien.ai/api/formdata/"+consts.GAME_ID
    );
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
  */
 console.log('a');
 
}

router.beforeEach(async (to, from, next) => {

  /*GET FORM DATA INFOS */
  //let { data } = await getData();

  /* SET GOOGLE ANALYTICS */
  if(to.name == undefined) {  var name = 'home' } else {  var name = to.name }
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}

  // Notre analytics
  // gtag('consent', 'default', {
  //   'analytics_storage': 'granted'
  // });
  gtag('set', name, to.path);
  gtag('event', 'page_view', {
    page_title: name,
    page_location: window.location.href,
    page_path: to.path,
  })
  
  // facebook
  fbq('track', 'PageView');

  next();
});